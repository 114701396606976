@import '@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

@include mat-core();

$esano-brand: (
  50: #f5e5e5,
  100: #e5bfbf,
  200: #d49595,
  300: #c36a6a,
  400: #b64a4a,
  500: #a92a2a,
  600: #a22525,
  700: #981f1f,
  800: #8f1919,
  900: #7e0f0f,
  A100: #ffb1b1,
  A200: #ff7e7e,
  A400: #ff4b4b,
  A700: #ff3131,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  )
);

$esano-accent-brand: (
  50: #e5ebf1,
  100: #bfcddc,
  200: #95acc5,
  300: #6a8bae,
  400: #4a729c,
  500: #2a598b,
  600: #255183,
  700: #1f4878,
  800: #193e6e,
  900: #0f2e5b,
  A100: #91b9ff,
  A200: #5e99ff,
  A400: #2b78ff,
  A700: 1268 ff,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$esano-custom-theme-primary: mat-palette($esano-brand, 500, 400, 700);
$esano-custom-theme-accent: mat-palette($esano-accent-brand, A200, A100, A400);

// The warn palette is optional (defaults to red).
$esano-custom-theme-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$esano-custom-theme: mat-light-theme((
  color: (
    primary: $esano-custom-theme-primary,
    accent: $esano-custom-theme-accent,
    warn: $esano-custom-theme-warn,
  )
));

@mixin esano-footer-color($esano-custom-theme) {
  $primary: map-get($esano-custom-theme, primary);
  $accent: map-get($esano-custom-theme, accent);
  $warn: map-get($esano-custom-theme, warn);

  app-footer {
    .footer-primary {
      background-color: mat-color($primary);
    }

    .footer-accent {
      background-color: mat-color($accent);
    }
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($esano-custom-theme);

@include esano-footer-color($esano-custom-theme);
