@import 'assets/styles/customized_theme';
// @import 'assets/styles/aas2_app';
@import 'assets/fonts/Roboto/robotoFont.scss';
@import 'assets/fonts/Abril_Fatface/abrilfatfaceFont.scss';
@import 'assets/fonts/Manrope/manropeFont.scss';
@import 'material-icons-font/sass/variables';
@import 'material-icons-font/sass/mixins';
$MaterialIcons_FontPath: '~material-icons-font/fonts';
@import 'material-icons-font/sass/main';
@import 'material-icons-font/sass/Regular';

// html, body { height: 100%; }
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
// body { height: 100%; margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.loading-size {
  width: 3rem;
  height: 3rem;
}

// Remove autofocus on buttons
button:focus {
  outline: none !important;
}

/*
.mat-checkbox-layout {
  white-space: normal !important;
}
*/

/*
.mat-radio-label {
  white-space: normal !important;
}
*/

.mat-row {
  height: auto !important;
}

.mat-cell {
  padding: min(16px) 8px 8px min(16px) !important;
}

.close-button {
  float: right;
  // top: -24px;
  // right: -24px;
}

/*
.close-icon {
  transition: 1s ease-in-out;
}
*/

/*
.close-icon:hover {
  transform: rotate(180deg);
}

::ng-deep .icon-outside .close-button {
  float: right;
  top: -52px;
  right: -52px;
}
::ng-deep .icon-outside .mat-dialog-container {
  overflow: unset;
}
*/

.smallLayout {
  width: 60%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.text-width {
  width: 90%;
}

/** Relative positioning of spinner **/
.spinnerPosition {
  top: 50%;
  left: 50%;
  margin: 0 auto;
  height: 250px;
}

/** Centered text **/
p.centering {
  text-align: center;
  line-height: 100px;
}

table {
  width: 100%;
}

.hide_overflow {
  overflow-x: hidden;
}

.spacing-right {
  margin-right: 5px;
}

.spacing-left {
  margin-left: 5px;
}

.text-padding {
  padding: 5px 10px;
}

.text-padding button {
  opacity: 0.75;
}

.filter-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.filter-radio-button {
  margin: 5px;
}

.card-header-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
